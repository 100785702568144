import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { isPunchoutStoreMode } from '@silkpwa/module/store-mode';
import { usePortalInfo } from '@silkpwa/module/portal';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { ICartSummaryData } from '@silkpwa/magento/api/cart-repository/util';
import styles from './style.css';
import { SummaryList } from './summary-list';
import { CartTotal } from './cart-total';
import { CheckoutButton } from './checkout-button';
import { ContinueShopping } from '../../continue-shopping';
import { CartRewards } from './cart-rewards';
import { FreeShippingProgresBar } from '../../free-shipping-progress-bar';

interface IOrderSummary {
    cartSummaryData: Array<ICartSummaryData>;
    cartCheckoutDisabled: boolean;
    cartFreeShippingProgressBar: string;
}

export const OrderSummary = ({
    cartSummaryData,
    cartCheckoutDisabled,
    cartFreeShippingProgressBar,
}: IOrderSummary) => {
    const t = usePhraseTranslater();
    const portalInfo = usePortalInfo();
    const config = useConfig();
    let isLoyaltyEnabled = false;
    if (config.extension_attributes) {
        isLoyaltyEnabled = config.extension_attributes.is_loyalty_program_enabled || false;
    }
    return (
        <div className={styles.orderSummaryWrap}>
            <div className={styles.orderSummary}>
                <p className={styles.header}>{t('Order summary')}</p>
                {isLoyaltyEnabled ? (
                    <CartRewards />
                ) : null}
                <SummaryList cartSummaryData={cartSummaryData} />
                <div>
                    <CartTotal cartSummaryData={cartSummaryData} />
                    {!cartCheckoutDisabled ? (
                        <>
                            <CheckoutButton />
                            {!isPunchoutStoreMode() && !portalInfo.isPortal ? (
                                <p className={styles.promoTip}>{t('Have a promo code? Add it in the next step.')}</p>
                        ) : null}
                        </>
                    ) : (
                        <p className={styles.checkoutDisabledText}>
                            {t('An item in your cart is unavailable, please remove it from the cart to proceed')}
                        </p>
                    )}
                </div>
            </div>
            <FreeShippingProgresBar cartFreeShippingProgressBar={cartFreeShippingProgressBar} customClass="afterSummary" />
            <ContinueShopping />
        </div>
    );
};
