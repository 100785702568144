import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const ItemSKU = ({ item }) => {
    const t = usePhraseTranslater();
    return (
        <p className={styles.itemNum}>
            <span className={styles.skuLabel}>
                {t('Item#')}
:
            </span>
            {' '}
            <span data-test="item-sku" className="weglot-exclude">{item.sku}</span>
        </p>
    );
};
