import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { OVERRIDE_CATEGORY_ID_OPTION_TYPE } from 'ui/util/override-categories/id-param-resolver';
import { RawSpan } from 'ui/component/raw/span';
import styles from './style.css';
import optionStyles from '../../option-style.css';

export const ItemConfigurableOptions = ({ item }) => {
    if (
        (!item.options || item.options.length <= 0) &&
        (!item.bundle_child_items || item.bundle_child_items.length <= 0)
    ) {
        return null;
    }

    return (
        <ul
            className={classes(
                optionStyles.options,
                styles.productInfo,
            )}
        >
            {item.options.map(o => (
                <li
                    className={classes(
                        styles.configurableOption,
                        (o?.option_type === OVERRIDE_CATEGORY_ID_OPTION_TYPE ? styles.hidden : ''),
                    )}
                    data-test={`option-${o.attribute_code}`}
                    key={o.label}
                >
                    <span className={optionStyles.optionLabel}>
                        {o.label}
                        :
                    </span>
                    {' '}
                    <RawSpan>{o.value}</RawSpan>
                </li>
            ))}

            {/* Bundle Options */}
            {item.bundle_child_items.map((o) => {
                let option = o;
                if (typeof o === 'string') {
                    option = JSON.parse(o);
                }

                return (
                    <li className={styles.bundleOption} key={option.sku}>
                        {option.qty}
                        x
                        {option.sku}
                    </li>
                );
            })}

        </ul>
    );
};
