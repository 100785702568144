import React from 'react';
import styles from './style.css';
import { Header } from './header';
import { Items } from './items';

export const LineItems = ({ items }) => (
    <div className={styles.lineItems}>
        <Header items={items} />
        <Items items={items} />
    </div>
);
