import React from 'react';
import styles from './style.css';
import { ItemHeader } from './item-header';
import { ItemSKU } from './item-sku';
import { ItemLoyaltyMessage } from './item-loyalty-message';
import { ItemConfigurableOptions } from './item-configurable-options';
import { EditItem } from './edit-item';
import { ItemQuantity } from './item-quantity';
import { ItemPrice } from '../item-price';
import { ItemGiftCardDetails } from './item-giftcard-details';

export const ItemInformation = ({ item }) => (
    <div className={styles.rightBlock}>
        <ItemHeader item={item} />
        <ItemSKU item={item} />
        <ItemLoyaltyMessage item={item} />
        <ItemConfigurableOptions item={item} />
        <ItemGiftCardDetails item={item} />
        <div className={styles.editArea}>
            <EditItem item={item} />
            <ItemQuantity item={item} />
            <ItemPrice price={item.price} testAttribute="item-price" className={styles.productPrice} />
        </div>
    </div>
);
