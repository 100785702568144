import React from 'react';
import { LineItem } from './line-item';
import styles from './style.css';

export const Items = ({ items }) => (
    <ul data-test="cart-items" className={styles.items}>
        {items.map(item => (
            <LineItem key={item.item_id} item={item} />
        ))}
    </ul>
);
