import React from 'react';
import { useContainerHook } from '@silkpwa/redux';
import { IUsePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';
import { getValue } from './util';

const SummaryItem = ({ item }) => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    const t = usePhraseTranslater();
    return (
        <p className={styles.summary}>
            {item.code === 'discount' ? (
                /* eslint-disable react/no-danger */
                <span className={styles.priceLabelDiscount}>
                    {t('DISCOUNT')}
                    <span className={styles.checkouttooltipicon}>
                        <span dangerouslySetInnerHTML={{ __html: item.title }} />
                    </span>
                </span>
            ) : <span className={styles.priceLabel}>{item.title}</span> }
            <span className={classes(styles.price, 'weglot-exclude')}>{getValue(formatPrice, item)}</span>
        </p>
    );
};

export const SummaryList = ({ cartSummaryData }) => (
    <div className={styles.labelWrap}>
        {cartSummaryData.filter(item => item.code !== 'rewards_calculations' && item.code !== 'rewards-total').map(item => (
            <SummaryItem key={item.code} item={item} />
        ))}
    </div>
);
