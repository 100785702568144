import React from 'react';
import { useContainerHook } from '@silkpwa/redux';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { LoadingImage } from 'ui/component/loading-image';
import { ICartPageProps } from 'ui/page/cart-page';
import { CartWithItems } from './cart-with-items';
import { EmptyCart } from './empty-cart';
import { CartRewardsMessage } from './cart-rewards-message';
import styles from './style.css';

export const Cart: React.FC<ICartPageProps> = ({
    cartItems,
    cartSummaryData,
    cartCheckoutDisabled,
    cartFreeShippingProgressBar,
}) => {
    const queryString = useContainerHook<() => any>('useQueryString');
    let isLoyaltyEnabled = false;
    const config = useConfig();
    if (config.extension_attributes) {
        isLoyaltyEnabled = config.extension_attributes.is_loyalty_program_enabled || false;
    }
    return (
        <div className={styles.cart}>
            {isLoyaltyEnabled && cartItems.length > 0 && (
                <CartRewardsMessage />
            )}
            {queryString.emb_flag ? (
                <div className={styles.loadingImageContainer}>
                    <LoadingImage parentClass={styles.logoUploadLoading} />
                </div>
            ) : ''}
            {cartItems.length > 0 && (
                <CartWithItems
                    cartItems={cartItems}
                    cartSummaryData={cartSummaryData}
                    cartCheckoutDisabled={cartCheckoutDisabled}
                    cartFreeShippingProgressBar={cartFreeShippingProgressBar}
                />
                )}
            {cartItems.length === 0 && (
                <EmptyCart />
                )}
        </div>
    );
};
