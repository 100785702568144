import React, { useState } from 'react';
import { useContainer } from '@silkpwa/redux';
import { IRewardsRepository } from '@silkpwa/magento/api/rewards-repository';

import styles from './style.css';

export const CartRewardsMessage: React.FC<{}> = () => {
    const r = useContainer<IRewardsRepository>('rewards');
    const [rewards, setRewards] = useState('');

    r.getRewards().then((value) => {
        setRewards(value.notification_message);
    });
    /* The product description can contain HTML, so we need to
use dangerouslySetInnerHTML. This doesn't introduce an XSS
vulnerability because it can only be set by CW employees */

    /* eslint-disable react/no-danger */
    return (
        <div
            data-test="loyalty_message_display"
            dangerouslySetInnerHTML={{
                __html: rewards,
            }}
            className={styles.cartPageRewardsMessages}
            id="cartPageRewardsMessages"
        />
    );
};
