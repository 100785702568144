import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import styles from './style.css';

export const ContinueShopping = (_) => {
    const t = usePhraseTranslater();
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();

    return isPunchoutInspectOperation ? null : (
        <p className={styles.continueShopping}>
            <a href="/">{t('CONTINUE SHOPPING')}</a>
        </p>
    );
};
