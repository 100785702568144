import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ContinueShopping } from '../continue-shopping';
import styles from './style.css';

export const EmptyCart = (_) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.noItems}>
            {t('Your cart is empty')}
            <ContinueShopping />
        </div>
    );
};
