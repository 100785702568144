import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';
import { ItemCount } from './item-count';

export const Header = ({ items }) => {
    const t = usePhraseTranslater();
    return (
        <p className={styles.header}>
            <span className={styles.headerText}>{t('CART')}</span>
            <span className={styles.itemCount}>
                <ItemCount items={items} />
            </span>
        </p>
    );
};
