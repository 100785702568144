import React from 'react';
import { useStoreMode, useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import styles from './style.css';

export const CheckoutButton: React.FC<{}> = (_) => {
    const mode = useStoreMode();

    return (
        <>
            {useIsPunchoutInspectOperation() ? '' : (
                <a
                    className={`${styles.checkoutBtn} no-spa`}
                    href={mode.checkOutUrl}
                    data-test="btn-checkout"
                >
                    {mode.checkoutButtonText}
                </a>
            )}
        </>
    );
};
