import React from 'react';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import styles from './style.css';

export const ItemName = ({ item }) => {
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();
    const name = (
        <span
            className={styles.name}
            data-test={`cart-item ${item.sku}`}
        >
            {item.name}
        </span>
    );
    return item.url ? <a href={isPunchoutInspectOperation ? window.location.href : item.url}>{name}</a> : name;
};
