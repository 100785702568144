import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import deleteIcon from 'assets/images/mockImgs/trashicon.png';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import styles from './style.css';

export const ItemRemove = ({ item }) => (
    <>
        {useIsPunchoutInspectOperation() ? '' : (
            <AccessibleButton
                tag="span"
                className={styles.deleteIcon}
                action={() => {
                    item.remove();
                }}
            >
                <img
                    src={deleteIcon}
                    data-test="delete-item"
                    alt=""
                />
            </AccessibleButton>
        )}
    </>
);
