import React from 'react';
import { ICartPageProps } from 'ui/page/cart-page';
import { CrossSellProductsWidget } from 'ui/component/product/product-widget/cross-sell';
import { OrderSummary } from './order-summary';
import { LineItems } from './line-items';
import styles from './style.css';
import { FreeShippingProgresBar } from '../free-shipping-progress-bar';

export const CartWithItems: React.FC<ICartPageProps> = ({
    cartItems,
    cartSummaryData,
    cartCheckoutDisabled,
    cartFreeShippingProgressBar,
}) => (
    <>
        <FreeShippingProgresBar cartFreeShippingProgressBar={cartFreeShippingProgressBar} customClass="beforeCart" />
        <div className={styles.main}>
            <LineItems items={cartItems} />
            <OrderSummary
                cartSummaryData={cartSummaryData}
                cartCheckoutDisabled={cartCheckoutDisabled}
                cartFreeShippingProgressBar={cartFreeShippingProgressBar}
            />
        </div>
        <CrossSellProductsWidget />
    </>
);
