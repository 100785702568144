import React from 'react';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import EmbroideryIcon from 'assets/images/mockImgs/EmbroideryIcon.png';
import closedArrow from 'assets/images/mockImgs/rightArrow.png';
import openedArrow from 'assets/images/mockImgs/downArrow.png';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import ExpandableList from './expandable-list';
import { EmbroideryItem } from './embroidery-item';
import { CustomizationItem } from './customization-item';
import { ItemPrice } from '../item-price';
import styles from './style.css';

export const EmbroideryItems = ({ item }) => {
    const t = usePhraseTranslater();
    if (item.embroidery_items.length <= 0) return null;
    const customizationItems: React.JSX.Element[] = [];
    const alterationItems: React.JSX.Element[] = [];
    const printScreenItems: React.JSX.Element[] = [];
    let specialInstructionItem: React.JSX.Element | null = null;

    return (
        <UseState initialState={false}>
            {([opened, setOpened]) => (
                <div className={styles.cartItemFoot}>
                    <AccessibleButton
                        tag="div"
                        className={styles.footExpand}
                        data-test={`product-options-${item.sku}`}
                        action={() => setOpened(!opened)}
                    >
                        <img className={styles.EmbroideryIcon} src={EmbroideryIcon} alt="" />
                        <span className={styles.expandText}>Product Options</span>
                        {' '}
                        <ItemPrice price={item.optionIncrement} before="+" className={styles.expandPrice} />
                        {!opened && <img className={styles.closedArrow} src={closedArrow} alt="" />}
                        {opened && <img className={styles.openedArrow} src={openedArrow} alt="" />}
                    </AccessibleButton>
                    {opened && (
                        <ul data-test={`product-options-list-${item.sku}`} className={styles.embroideryItems}>
                            {
                                item.embroidery_items.map((i) => {
                                    if (i.alteration_type !== '') {
                                        if (i.alteration_type === 'alteration_and_piping' || i.alteration_type === 'alteration_piping') {
                                            alterationItems.push(<CustomizationItem key={i.item_id} item={i} />);
                                        } else if (i.alteration_type === 'print_screen') {
                                            printScreenItems.push(<CustomizationItem key={i.item_id} item={i} />);
                                        } else {
                                            customizationItems.push(<CustomizationItem key={i.item_id} item={i} />);
                                        }
                                        return null;
                                    }
                                    if (i.name && typeof i.name === 'string' && i.name.toLowerCase() === 'special instructions') {
                                        specialInstructionItem = <EmbroideryItem key={i.item_id} item={i} />;
                                        return null;
                                    }
                                    return (
                                        <EmbroideryItem key={i.item_id} item={i} />
                                    );
                                })
                            }
                            {
                                (
                                    customizationItems.length > 0 ||
                                    alterationItems.length > 0 ||
                                    printScreenItems.length > 0
                                ) && (
                                <li>
                                    <div className={styles.customizationName}>{t('CUSTOMIZATION')}</div>
                                    <ul data-test={`customization-list-${item.sku}`} className={styles.customizationCnt}>
                                        <ExpandableList
                                            title="Alteration"
                                            items={alterationItems}
                                            styles={styles}
                                        />
                                        <ExpandableList
                                            title="Print Screen"
                                            items={printScreenItems}
                                            styles={styles}
                                        />
                                        {customizationItems.length > 0 && customizationItems.map(i => i)}
                                    </ul>
                                </li>
                            )}
                            {specialInstructionItem && specialInstructionItem}
                        </ul>
                    )}
                </div>
            )}
        </UseState>
    );
};
