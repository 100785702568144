import React from 'react';
import { useContainerHook } from '@silkpwa/redux';
import { IUsePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface ItemPriceProps {
    price: any;
    testAttribute?: any;
    className?: any;
    before?: any;
}

export const ItemPrice = ({
    price,
    testAttribute,
    className,
    before,
}: ItemPriceProps) => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');

    return (
        <span
            data-test={testAttribute}
            data-price={price}
            className={classes(styles.productPrice, {
                [className]: className,
            }, 'weglot-exclude')}
        >
            {before || ''}
            {formatPrice(price)}
        </span>
    );
};
