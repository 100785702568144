import React from 'react';
import { MainColumn } from 'ui/component/main-column';
import screenSwitch from 'ui/styles/screen-switch.css';
import { connectCrossSell } from '@silkpwa/module/react-component/connect-cross-sell';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import styles from './style.css';
import { FullProductSlider as ProductSlider } from '../product-slider';

interface CrossSellWidgetImplProps {
    products: Array<object>;
}

const CrossSellProductsWidgetImpl = ({ products }: CrossSellWidgetImplProps) => {
    const config = useConfig();
    if (products.length === 0 || !config.cross_sell_block_enable) return null;
    return (
        <MainColumn className={styles.crossSellProductsMainColumn}>
            <div className={styles.title}>{config.cross_sell_block_title}</div>
            <div className={screenSwitch.showOnDesktop}>
                <ProductSlider items={products} show={5} location="crossSellProducts" />
            </div>
            <div className={`${screenSwitch.showOnLargeMobile} ${screenSwitch.showOnMediumMobile}`}>
                <ProductSlider items={products} show={3} location="crossSellProducts" />
            </div>
            <div className={screenSwitch.showOnSmallMobile}>
                <ProductSlider items={products} show={2} location="crossSellProducts" />
            </div>
            <div className={screenSwitch.showOnVerySmallMobile}>
                <ProductSlider items={products} show={1} location="crossSellProducts" />
            </div>
        </MainColumn>
    );
};

export const CrossSellProductsWidget = connectCrossSell(CrossSellProductsWidgetImpl);
