import React, { useState } from 'react';
import { useContainer } from '@silkpwa/redux';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { connectCart } from '@silkpwa/module/react-component/connect-cart';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IRewardsRepository } from '@silkpwa/magento/api/rewards-repository';
import { ShowLoader } from 'ui/component/show-loader';
import { ApplyPointsButton } from './apply-points-button';
import { CancelPointsButton } from './cancel-points-button';
import styles from './style.css';

interface CartRewardsProps {
    account: AccountState;
    getCartList: () => any;
}

const CartRewards: React.FC<CartRewardsProps> = ({ account, getCartList }) => {
    const DEFAULT = 99999;
    const INPUT = 'INPUT';
    const t = usePhraseTranslater();
    const r = useContainer<IRewardsRepository>('rewards');
    const [amount, setAmount] = useState(0);
    const [maxAmount, setMaxAmount] = useState(0);
    const [point, setPoint] = useState(0);
    const [message, setMessage] = useState('');
    const [formatStrings, setFormatStrings] = useState<any | null>(null); /* TODO refactor needed */
    const [checked, setChecked] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [pointSpend, setPointSpend] = useState(0);
    const [tagName, setTagName] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [flag, setFlag] = useState(false);
    if (amount === 0 && !flag) {
        r.getRewardInfo().then((value) => {
            const {
                amount: newAmount,
                max_amount: newMaxAmount = 0,
                format_strings: newFormatStrings,
                spend: newSpend = 0,
                visible_on_cart: newIsVisible,
            } = value;
            setAmount(newAmount);
            setMaxAmount(newMaxAmount);
            setFormatStrings(newFormatStrings);
            setIsVisible(newIsVisible);

            if (pointSpend === 0 && newSpend > 0 && tagName !== INPUT) {
                setPointSpend(newSpend);
            }
            setFlag(true);
        });
    }

    function setRewardsData(intPoint: any) { /* TODO refactor needed */
        setPointSpend(intPoint);
        if (intPoint === DEFAULT) {
            setPointSpend(0);
        }
        setPoint(intPoint);
    }

    function textInputValidation(data: string, tag: string) {
        setTagName(tag);
        if (!data) {
            setRewardsData(0);
        }

        if (/^\d*\.?\d*$/.test(data)) {
            setRewardsData(data);
        }
    }

    function applyRewardsAction(points: number, data: string) {
        if (data === 'default') {
            setChecked(false);
            setPointSpend(0);
        }
        let pointValidation = points;
        if (data === 'max' && checked) {
            pointValidation = DEFAULT;
        }
        if (pointValidation > 0) {
            setProcessing(true);
            r.applyRewards(pointValidation).then((value) => {
                setMessage(value);
                getCartList();
                setProcessing(false);
            });
        }
        setRewardsData(points);
    }
    setTimeout(() => {
        setMessage('');
    }, 10000);

    if (!account.isLoggedIn || amount <= 0 || !isVisible) {
        return null;
    }
    return (

        <div className={styles.rewardsSummaryWrap}>
            <div className={styles.rewardsSummary}>
                <p className={styles.rewardsHead}>{formatStrings.unit.useRewards}</p>
                <p className={styles.header}>
                    {formatStrings.unit.availableRewards}
                    <span className={styles.priceGreenColor}>
                        {formatStrings.balance}
                    </span>
                </p>
                <div className={styles.rewardAppliedMessage}>
                    {message}
                </div>
                <input
                    className={styles.input_text}
                    data-test="rewards-points-input"
                    type="text"
                    maxLength={6}
                    value={pointSpend}
                    id="rewardspoint"
                    onChange={(event) => {
                        textInputValidation(event.target.value, event.target.tagName);
                    }}
                    placeholder={formatStrings.unit.placeHolder}
                    name="rewardspoint"
                />
                <div>
                    <div className={styles.buttons}>
                        <ApplyPointsButton action={() => applyRewardsAction(point, 'onDemand')} buttonLabel={formatStrings.unit.appButton} />
                        <CancelPointsButton action={() => applyRewardsAction(DEFAULT, 'default')} buttonLabel={formatStrings.unit.canButton} />
                        <div className={styles.loader}>
                            {processing ? <ShowLoader message={t('Processing request...')} /> : null}
                        </div>
                    </div>
                    <div className={styles.rewardsApplySection}>
                        <input
                            className={styles.inputCheckbox}
                            type="checkbox"
                            data-test="rewards-points-checkbox"
                            id="applyrewards"
                            onClick={() => applyRewardsAction(maxAmount, 'max')}
                            onChange={() => setChecked(!checked)}
                            checked={checked}
                            name="applyrewards"
                        />
                        <p className={styles.header}>
                            {formatStrings.unit.useMaxRewards}
                            <span className={styles.priceGreenColor}>
                                {formatStrings.maxBalance}
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ConnectedInput = connectCart(connectAccount(CartRewards));

export { ConnectedInput as CartRewards };
