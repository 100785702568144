import React from 'react';
import { ItemImage } from './item-image';
import { ItemInformation } from './item-information';
import { EmbroideryItems } from './embroidery-items';
import styles from './style.css';

export const LineItem = ({ item }) => (
    <li data-test={`cart-item-${item.sku}`} className={styles.lineItem}>
        <ItemImage item={item} />
        <ItemInformation item={item} />
        <EmbroideryItems item={item} />
    </li>
);
