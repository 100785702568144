import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

/* eslint-disable react/no-danger */
export const FreeShippingProgresBar = ({ cartFreeShippingProgressBar, customClass }) => (
    <div className={
        classes(
            styles.freeShipingProgressBar,
            `${customClass}`,
        )}
    >
        <div
            dangerouslySetInnerHTML={{
                __html: cartFreeShippingProgressBar,
            }}
        />
    </div>
);
