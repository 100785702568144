import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICartItem } from 'ui/util/select-cart-items';
import { ItemName } from './item-name';
import { ItemRemove } from './item-remove';
import styles from './style.css';

interface IItemHeaderProps {
    item: ICartItem;
}

export const ItemHeader: React.FC<IItemHeaderProps> = ({
    item,
}) => {
    const t = usePhraseTranslater();
    return (
        <p className={styles.headWrap}>
            <ItemName item={item} />
            {
                !item.product_is_salable && (
                    <span className={styles.oosNotification}>
                        {t('Product is no longer available, please remove from cart')}
                    </span>
                )
            }
            {item.userCanEdit && (
                <ItemRemove item={item} />
            )}
        </p>
    );
};
