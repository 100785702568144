import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import styles from './style.css';

interface CancelPointsButtonProps {
    action: any;
    buttonLabel: string;
}
export const CancelPointsButton: React.FC<CancelPointsButtonProps> = ({ action, buttonLabel }) => (
    <AccessibleButton
        tag="div"
        data-test="cancel-points-button"
        action={action}
        className={classes(styles.cancelPointsButton, {
            [styles.disabled]: '',
        })}
    >
        {buttonLabel}
    </AccessibleButton>
);
