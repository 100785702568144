import React from 'react';
import { RawSpan } from 'ui/component/raw/span';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';
import { ItemPrice } from '../../item-price';
import optionStyles from '../../option-style.css';

export const EmbroideryItem: React.FC<any> = ({ item }) => {
    const t = usePhraseTranslater();
    return (
        <li data-test={`embroidery-option-${item.name}`}>
            <div className={styles.embroideryName}>{item.name}</div>
            <div className={styles.embroideryQuantity}>
                {t('Qty')}
                {' '}
                {item.qty}
            </div>
            <ItemPrice price={item.price * item.qty} className={styles.embroideryPrice} />
            <ul className={optionStyles.options}>
                {item.options.map((o) => {
                    if (o.option_code.endsWith('_path')) {
                        return '';
                    }
                    const optionValues = o.value.split('|');
                    if (o.option_code === 'embroidered_flags') {
                        const [flag1, flag2] = optionValues;
                        return (
                            <li key={o.label}>
                                <span className={optionStyles.optionLabel}>
                                    {o.label}
                                :
                                </span>
                                {' '}
                                <RawSpan data-test={`embroidery-option-value-${o.option_code}`}>{flag1}</RawSpan>
                                {' '}
                                <RawSpan data-test={`embroidery-option-value-${o.option_code}`}>{flag2}</RawSpan>
                            </li>
                        );
                    }
                    return (
                        <li key={o.label}>
                            <span className={optionStyles.optionLabel}>
                                {o.label}
                            :
                            </span>
                            {' '}
                            <RawSpan data-test={`embroidery-option-value-${o.option_code}`}>{o.value}</RawSpan>
                        </li>
                    );
                })}
            </ul>
        </li>
    );
};
